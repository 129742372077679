import React, { useRef, useState } from "react";
import { BsFillPinMapFill } from 'react-icons/bs';
import { AiOutlinePhone } from 'react-icons/ai';
import { AiOutlineMail } from 'react-icons/ai';
import { Link } from "react-router-dom";
import { Link as LinkRoll } from "react-scroll";
import Fade from 'react-reveal/Fade';
import './ContactForm.css';
import emailjs from 'emailjs-com';

const ContactForm = () => {
    const form = useRef();
    const [buttonText, setButtonText] = useState("Submit");

    const sendEmail = (e) => {
        e.preventDefault();

        // Sending email using emailjs
        emailjs.sendForm("service_bdfl0ie", "template_z74kahd", form.current, 'CLcHWAKSemVMd1_sU')
            .then((result) => {
                console.log(result.text);
                setButtonText("Message Sent");
            }, (error) => {
                console.log(error.text);
            });

        e.target.reset();
    };

    return (
        <div className="contact-form-content">
            <section className="contact-container container">

                <div className="get_form_inner">
                    <div className="get_form_inner_text">
                        <h3>Get In Touch</h3>
                        <p><i>We'll keep your contact information strictly confidential.</i></p><br />
                    </div>
                    <form ref={form} onSubmit={sendEmail}>
                        <div className="inputs">
                            <input 
                                type="text" 
                                name="name" 
                                placeholder="Your Name" 
                                required 
                                pattern="[A-Za-z\s]+" 
                                title="Please enter a valid name (letters and spaces only)" 
                            /> 
                            <br />
                            <input 
                                type="email" 
                                name="email" 
                                placeholder="Your Email" 
                                required 
                                title="Please enter a valid email address" 
                            /> 
                            <br />
                            <input 
                                type="tel" 
                                name="phone" 
                                placeholder="Your Phone" 
                                required 
                                pattern="\+?[0-9\s\-\(\)]+" 
                                title="Please enter a valid phone number (digits, spaces, and symbols like +, -, () are allowed)" 
                            /> 
                            <br />
                            <input 
                                type="text" 
                                name="subject" 
                                placeholder="Subject" 
                                required 
                                pattern="[A-Za-z0-9\s\-]+" 
                                title="Please enter a valid subject (letters, numbers, spaces, and hyphens only)" 
                            /> 
                            <br />
                        </div>
                        <div>
                            <textarea 
                                name="message" 
                                placeholder="How can we help?" 
                                cols="30" 
                                rows="8" 
                                required 
                                pattern="[A-Za-z0-9\s\-,.!?]+" 
                                title="Please enter a valid message (letters, numbers, spaces, and punctuation are allowed)"
                            ></textarea>

                      <div className="disclaimer">
                        <p>Santa Monica Detox needs the contact information you provide to us to contact you about our products and services. You may unsubscribe from these communications at any time. For information on how to unsubscribe, as well as our privacy practices and commitment to protecting your privacy, please review our  
                        <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                            <Link to="/privacy-policy"><span> Privacy Policy </span></Link>
                        </LinkRoll></p>
                      </div>


                            <input type="submit" value={buttonText} />
                        </div>
                    </form>
                </div>
            </section>

            <Fade left>
                <div className="contact-cards-container">
                    <div className="get_form_inner2">
                        <h2>CALL US</h2>
                        <p><AiOutlinePhone /><a href="tel:3234439924"> +1 (323) 443-9924</a></p>
                    </div>
                    <div className="get_form_inner2">
                        <h2>EMAIL</h2>
                        <p><AiOutlineMail /> info@SantaMonicaDetox.biz  </p><br />
                    </div>
                    <div className="get_form_inner2">
                        <h2>LOCATION</h2>
                        <p><BsFillPinMapFill /> 729 17th St, Santa Monica, Ca 90402</p>
                    </div>
                </div>
            </Fade>
        </div>
    );
}

export default ContactForm;
